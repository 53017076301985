










































import { IsoCurrencyEnum } from "@core/store/types/common/enums/isoCurrencyEnum";
import { formatEmptyString } from "@core/filters";
import { filteredDataBySearch } from "@core/helpers/filteredDataBySearch";
import { CurrencyEnum } from "@core/store/types/common/enums/currencyEnum";
import { withDefaults, defineProps, defineEmits } from "@vue/runtime-dom";
import { ComponentPublicInstance, computed, ref } from "@vue/composition-api";
interface Props {
  value?: string;
  local?: boolean;
  disabled?: boolean;
}
const __sfc_main = {};
__sfc_main.props = {
  value: {
    key: "value",
    required: false,
    type: String
  },
  local: {
    key: "local",
    required: false,
    type: Boolean,
    default: false
  },
  disabled: {
    key: "disabled",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const input = ref<ComponentPublicInstance<any> | null>(null);
  const dropdown = ref<ComponentPublicInstance<any> | null>(null);
  const label = ref<string | null>(null);
  const selectValue = computed({
    get: () => props.value,
    set: value => emit("input", value)
  });
  const items = computed(() => Object.values(props.local ? CurrencyEnum : IsoCurrencyEnum).map(value => ({
    value
  })));
  const currencies = computed(() => label.value ? filteredDataBySearch(items.value, label.value, ["value"]).items : items.value);
  function onActiveChange() {
    label.value = null;
    input?.$refs.input.focus();
  }
  function onToggle() {
    dropdown?.toggle();
  }
  return {
    formatEmptyString,
    input,
    dropdown,
    label,
    selectValue,
    currencies,
    onActiveChange,
    onToggle
  };
};
export default __sfc_main;
