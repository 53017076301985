












import Select from "@/components/Common/Select/Select.vue";
import { withDefaults, defineProps, defineEmits } from "@vue/runtime-dom";
import { computed, onUnmounted } from "@vue/composition-api";
import { useBlockedList } from "@/stores/admin/lists/blockedListStore";
import { storeToRefs } from "pinia";
interface Props {
  value: boolean | null;
}
const __sfc_main = {};
__sfc_main.props = {
  value: {
    key: "value",
    required: false,
    type: [Boolean, null],
    default: null
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const store = useBlockedList();
  const {
    data
  } = storeToRefs(store);

  // TODO: Переписать ан defineModel в vue 3
  const isBlocked = computed({
    get: () => props.value,
    set: value => emit("input", value)
  });
  function getIsBlocked() {
    store.GET_BLOCKED();
    return data.value;
  }
  onUnmounted(() => {
    store.$reset();
  });
  return {
    isBlocked,
    getIsBlocked
  };
};
__sfc_main.components = Object.assign({
  Select
}, __sfc_main.components);
export default __sfc_main;
