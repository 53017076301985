<template>
    <form @submit.prevent.stop="submit">
        <div class="columns mb-4">
            <div class="column is-3">
                <b-field :label="$t(`common.entity.callCenters.labels.name`)">
                    <LInput
                        v-model="name"
                        :placeholder="$t(`common.entity.callCenters.labels.name`)"></LInput>
                </b-field>
            </div>
            <div class="column is-3">
                <b-field :label="$t(`common.entity.callCenters.labels.timeZone`)">
                    <b-select
                        v-model="timeZone"
                        expanded>
                        <optgroup
                            :key="continent"
                            v-for="(zones, continent) in timeZones"
                            :label="continent">
                            <option
                                :key="i"
                                v-for="(timeZone, i) in zones"
                                :value="timeZone.offset">
                                {{ timeZone.alias }} {{ timeZone.offset }}
                            </option>
                        </optgroup>
                    </b-select>
                </b-field>
            </div>
        </div>

        <FormTimetable
            :editable-call-center="editableCallCenter"
            @update:timetable="timetable = $event"
            @update:workingAllDay="workingAllDay = $event">
        </FormTimetable>

        <b-button
            expanded
            native-type="submit"
            type="is-success is-light">
            {{ $t(`common.entity.callCenters.buttons.accept`) }}
        </b-button>
        <LLoading
            :active="isLoading"
            :is-full-page="false"></LLoading>
    </form>
</template>

<script>
  import { CallCenters } from "@core/mixins";
  import moment from "moment";
  import FormTimetable from "@/components/Admin/Users/AdvertisersTab/CallСenters/CallCentersForm/FormTimetable";
  import LLoading from "@/components/Common/LLoading";

  export default {
    name: "CallCenterForm",
    mixins: [CallCenters],
    components: {
      LLoading,
      FormTimetable
    },
    props: {
      actionOnSave: {
        type: Function,
        default: () => {}
      },
      editableCallCenter: {
        type: Object,
        default: null
      }
    },
    created () {
      this.timeZone = this.isEdit ? this.editableCallCenter.timeZone : this.userTimeZone.offset;
      this.name = this.isEdit ? this.editableCallCenter.name : "";
    },
    data () {
      return {
        name: "",
        timeZone: null,
        timetable: {},
        isLoading: false,
        workingAllDay: []
      };
    },
    computed: {
      userTimeZone () {
        return this.$store.getters.timezone;
      },
      timeZones () {
        return this.$store.state.dictionaries.timeZones;
      },
      isEdit () {
        return !!this.editableCallCenter;
      },
      format () {
        return "YYYY-MM-DDTHH:mm:ss+00:00";
      }
    },
    methods: {

      async submit () {
        if (!this.isLoading) {
          this.isLoading = true;
          const format = `YYYY-MM-DDTHH:mm:ss${ this.timeZone }`;
          const formatTimetable = {};

          //Преобразуем все даты в нужный для бека формат
          for (const day in this.timetable) {
            if (this.timetable[day].workAllDay) {
              formatTimetable[day] = [{
                timeStart: moment(`${ String(this.currentYear) }-${ this.currentMonth }-01`).format(format),
                timeFinish: moment(`${ String(this.currentYear) }-${ this.currentMonth }-02`).format(format)
              }];
            } else
              // FixMe: следует заменить reduce
              formatTimetable[day] = this.timetable[day].times.map(time => {
                if (time.timeStart && time.timeFinish) {
                  return {
                    timeStart: moment(time.timeStart).format(format),
                    timeFinish: moment(time.timeFinish).format(format)
                  };
                }
              }).filter(time => time);

          }
          const callCenter = {
            name: this.name || this.$t("common.entity.callCenters.labels.defaultName"),
            timeTables: formatTimetable,
            timeZone: this.timeZone
          };
          try {
            await this.actionOnSave(callCenter);
            this.isLoading = false;

          } catch (e) {
            this.isLoading = false;
            throw new Error(e);
          }

        }
      }
    }
  };
</script>


