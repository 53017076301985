<template>
    <ModalBox
        :is-active="isModalActive"
        :on-cancel="currentFile !== 'list' ? () => closeModalOnForm() : null"
        @close="UPDATE_CALLCENTERS_MODAL_ACTIVE(false)">
        <template #header>
            <div class="columns is-vcentered">
                <div
                    v-if="currentFile !== 'list'"
                    class="is-clickable"
                    @click="backToList">
                    <b-icon
                        icon="angle-left"
                        size="is-small"></b-icon>
                    {{ $t("common.entity.callCenters.labels.back") }}
                </div>
                <h5 class="subtitle is-narrow column">
                    {{ $t(`common.entity.callCenters.labels.${ currentFile }`) }}
                </h5>
            </div>
        </template>
        
        <CallCentersList
            v-if="currentFile === 'list'"
            :get-call-centers="() => getCallCenters()"
            :is-loading="isLoading"
            :per-page="perPage"
            @openForm="openForm($event)"
            @updatePerPage="perPage += 12">
        </CallCentersList>
        <AddCallCenter
            v-if="currentFile === 'create'"
            :advertiser-id="advertiserId"
            @changeFile="changeFile($event)">
        </AddCallCenter>
        <EditCallCenter
            v-if="currentFile === 'edit'"
            :editable-call-center="editableCallCenter"
            @changeFile="changeFile($event)">
        </EditCallCenter>
    </ModalBox>
</template>

<script>
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import { GET_CALLCENTERS } from "@core/store/action-constants";
  import { mapActions, mapMutations, mapState } from "vuex";
  import AddCallCenter from "@/components/Admin/Users/AdvertisersTab/CallСenters/AddCallCenter";
  import CallCentersList from "@/components/Admin/Users/AdvertisersTab/CallСenters/CallCentersList";
  import EditCallCenter from "@/components/Admin/Users/AdvertisersTab/CallСenters/EditCallCenter";
  import { UPDATE_CALLCENTERS_MODAL_ACTIVE } from "@core/store/mutation-constants";

  export default {
    name: "CallCenters",
    components: {
      AddCallCenter,
      EditCallCenter,
      CallCentersList,
      ModalBox
    },
    data () {
      return {
        currentFile: "list",
        editableCallCenter: null,
        currentPage: 1,
        perPage: 12
      };
    },
    computed: {
      ...mapState("admin", {
        isModalActive: state => state.callCenters.isCallCentersModalActive,
        advertiserId: state => state.callCenters.advertiserIdCallCenters
      }),

      isLoading () {
        return this.$wait(`admin/${ GET_CALLCENTERS }`);
      }
    },
    methods: {
      ...mapActions("admin", [
        GET_CALLCENTERS
      ]),
      ...mapMutations("admin", [
        UPDATE_CALLCENTERS_MODAL_ACTIVE
      ]),
      async getCallCenters () {
        const { advertiserId, perPage, currentPage } = this;
        const params = {
          filters: {
            advertisers: [advertiserId]
          },
          limit: perPage,
          offset: (currentPage - 1) * perPage
        };
        await this.GET_CALLCENTERS(params);
      },
      changeFile (event) {
        this.currentFile = event;
        this.getCallCenters();
      },
      openForm (callCenter) {
        this.editableCallCenter = callCenter;
        this.currentFile = callCenter ? "edit" : "create";
      },
      backToList () {
        this.$buefy.dialog.confirm({
          title: this.$t("common.entity.callCenters.modals.backToList.title"),
          message: this.$t("common.entity.callCenters.modals.backToList.message"),
          confirmText: this.$t("common.entity.callCenters.modals.backToList.confirmButtonText"),
          cancelText: this.$t("common.entity.callCenters.buttons.cancelButtonText"),
          type: "is-info is-light",
          onConfirm: () => {
            this.currentFile = "list";
            this.editableCallCenter = null;
          }
        });
      },
      closeModalOnForm () {
        this.$buefy.dialog.confirm({
          title: this.$t("common.entity.callCenters.modals.closeModalForm.title"),
          message: this.$t(`common.entity.callCenters.modals.closeModalForm.${ this.currentFile }`),
          confirmText: this.$t("common.entity.callCenters.modals.closeModalForm.confirmButtonText"),
          cancelText: this.$t("common.entity.callCenters.buttons.cancelButtonText"),
          type: "is-danger is-light",
          onConfirm: () => {
            this.UPDATE_CALLCENTERS_MODAL_ACTIVE(false);
            this.currentFile = "list";
          }
        });
      }
    },
    watch: {
      advertiserId () {
        this.getCallCenters();
        this.perPage = 12;
      }
    }
  };

</script>

<style lang="scss" scoped>
    .modal::v-deep {
        .animation-content {
            max-width: 100% !important;
        }

        .modal-card {
            max-width: 100%;
            width: auto;

            .modal-card-foot {
                padding: 10px;
            }
        }
    }
</style>
