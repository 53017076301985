

























import OffersSelect from "@/components/Common/Select/OffersSelect.vue";
import WebmastersSelect from "@/components/Common/Select/WebmastersSelect.vue";
import { defineProps } from "@vue/runtime-dom";
import { Advertiser } from "@core/store/types/common/User";
import { useAdvertisersDetail } from "@/stores/admin/users/advertisers/advertisersDetailStore";
import { storeToRefs } from "pinia";
interface Props {
  advertiser: Advertiser;
}
const __sfc_main = {};
__sfc_main.props = {
  advertiser: {
    key: "advertiser",
    required: true,
    type: null
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const detailStore = useAdvertisersDetail();
  const {
    filters
  } = storeToRefs(detailStore);
  return {
    filters
  };
};
__sfc_main.components = Object.assign({
  WebmastersSelect,
  OffersSelect
}, __sfc_main.components);
export default __sfc_main;
