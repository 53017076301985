import { defineStore } from "pinia";
import { AdvertisersItem } from "@core/store/types/admin/users/advertisers/AdvertisersState";
import { AdvertisersRate, AdvertisersRatesFilters } from "@core/store/types/admin/users/advertisers/Detail";
import { PaginationOutput } from "@core/store/types/PaginationOutput";
import { AdvertisersDetailService } from "@core/services/admin/users/advertisers/AdvertisersDetailService";

export const useAdvertisersDetail = defineStore("admin/users/advertisers/detail", {
  state: () => ({
    isActive: false,
    advertiser: <AdvertisersItem>{},
    data: null as PaginationOutput<AdvertisersRate[]> | null,
    filters: <AdvertisersRatesFilters>{
      offer: null,
      webmaster: null
    }
  }),

  getters: {
    advertiserId: ({ advertiser }) => advertiser.id
  },

  actions: {
    async GET_ADVERTISER_RATES () {
      this.data = await AdvertisersDetailService.getRates(this.advertiser.id, this.filters);
    }
  }
});