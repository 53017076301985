<template>
    <div>
        <FormTimetableDayWrapper :title="$t(`common.entity.callCenters.daysOfWeek.full.${dayName}`)">
            <template #params>
                <b-checkbox
                    v-model="day_.workAllDay"
                    :disabled="isMasterDay"
                    :native-value="dayName">
                    <LTooltip
                        :active="!isMasterDay"
                        :label="$t('common.entity.callCenters.labels.allDayTooltip')"
                        position="is-bottom"
                        type="is-dark">
                        {{ $t("common.entity.callCenters.labels.allDay") }}
                    </LTooltip>
                </b-checkbox>
            </template>
    
            <template #times>
                <DayTimeRow
                    :key="index"
                    v-for="(_, index) in day.times"
                    :disabled="day.workAllDay || isMasterDay"
                    :index="index"
                    :time.sync="day_.times[index]"
                    @deleteTime="deleteTime">
                </DayTimeRow>
            </template>
    
            <template #footer>
                <b-button
                    :disabled="day.workAllDay || isMasterDay"
                    expanded
                    icon-left="plus"
                    size="is-small"
                    type="is-light is-info"
                    @click="addTime">
                </b-button>
            </template>
        </FormTimetableDayWrapper>
    
        <AddGapNextDayModal
            :is-active.sync="AddGapNextDayModalActive"
            :is-next-master-day="isNextMasterDay"
            :time="{ ...time } || emptyTime"
            @close="debouncedCloseModal"
            @confirm="debouncedAddGapNextDay">
        </AddGapNextDayModal>
    </div>
</template>

<script>
  import { CallCenters } from "@core/mixins";
  import _debounce from "lodash/debounce";
  import AddGapNextDayModal from "@/components/Admin/Users/AdvertisersTab/CallСenters/CallCentersForm/AddGapNextDayModal";
  import DayTimeRow from "@/components/Admin/Users/AdvertisersTab/CallСenters/CallCentersForm/DayTimeRow";
  import FormTimetableDayWrapper from "@/components/Admin/Users/AdvertisersTab/CallСenters/CallCentersForm/FormTimetableDayWrapper";
  
  export default {
    name: "FormTimetableDay",
    mixins: [CallCenters],
    components: {
      DayTimeRow,
      FormTimetableDayWrapper,
      AddGapNextDayModal
    },
    props: {
      day: {
        type: Object,
        default: null
      },
      dayName: {
        type: String,
        default: null
      },
      selectedDays: {
        type: Array,
        default: null
      },
      masterDay: {
        type: Object,
        default: null
      }
    },

    data () {
      return {
        AddGapNextDayModalActive: false,
        time: null
      };
    },

    computed: {
      day_: {
        get () {
          return this.day;
        },
        set (value) {
          this.$emit("update:day", value);
        }
      },
      emptyTime () {
        return {
          timeStart: null,
          timeFinish: null
        };
      },
      isMasterDay () {
        return this.selectedDays.includes(this.dayName);
      },

      nextDayIndex () {
        const currentDayIndex = this.daysOfWeek.indexOf(this.dayName);
        return currentDayIndex === this.daysOfWeek.length - 1 ? 0 : currentDayIndex + 1;
      },

      isNextMasterDay () {
        return this.selectedDays.includes(this.daysOfWeek[this.nextDayIndex]);
      },

      debouncedAddGapNextDay () {
        return _debounce(this.addGapNextDay, 200, { trailing: true });
      },

      debouncedCloseModal () {
        return _debounce(this.closeModal, 150, { trailing: true });
      }
    },
    methods: {
      addTime () {
        this.day_.times.push({ ...this.emptyTime });
      },

      deleteTime (index) {
        if (index === 0) {
          this.$set(this.day_.times, 0, { ...this.emptyTime });
        } else {
          this.day_.times.splice(index, 1);
        }
      },

      addGapNextDay () {
        if (this.isNextMasterDay) {
          this.$emit("removeDay", this.daysOfWeek[this.nextDayIndex]);
        }
        this.$emit("addGapNextDay", { dayName: this.dayName, timeFinish: this.time.timeFinish });

        this.$set(this.time, "timeFinish", new Date(this.currentYear, this.currentMonth, 2));
      },

      closeModal () {
        this.$set(this.time, "timeFinish", null);
      }

    },
    watch: {
      day: {
        handler (value){
          value.times.forEach(time => {
            if (time.timeFinish && !this.isMidnight(time.timeFinish)) {
              time.timeFinish.setDate(1);
            }

            if (time.timeFinish && !this.isMidnight(time.timeFinish) && time.timeStart?.getTime() > time.timeFinish?.getTime()) {
              this.AddGapNextDayModalActive = true;
              this.time = time;
            }
          });
        },
        deep: true
      }
    }
  };
</script>

<style scoped>

</style>
