<template>
    <CallCenterForm :action-on-save="(callCenter) => createCallCenter(callCenter)">
    </CallCenterForm>
</template>

<script>
  import { CREATE_CALLCENTER } from "@core/store/action-constants";
  import { mapActions } from "vuex";
  import CallCenterForm from "@/components/Admin/Users/AdvertisersTab/CallСenters/CallCentersForm/CallCenterForm";
  
  export default {
    name: "AddCallCenter",
    components: {
      CallCenterForm
    },
    props: {
      advertiserId: {
        type: String,
        default: null
      }
    },
    methods: {
      ...mapActions("admin", [
        CREATE_CALLCENTER
      ]),
      async createCallCenter (callCenter) {
        await this.CREATE_CALLCENTER({
          advertiserId: this.advertiserId,
          ...callCenter
        });
        this.$buefy.toast.open({
          message: this.$t("common.entity.callCenters.messages.successCreation"),
          type: "is-success"
        });
        this.$emit("changeFile", "list");
      }
    }

  };
</script>

<style lang="scss" scoped>

</style>
