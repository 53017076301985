import { defineStore } from "pinia";
import { ManagementCompanyStore } from "@core/store/types/admin/users/advertisers/AdvertisersState";
import { momentToDate } from "@core/helpers/momentToDate";
import momentjs from "moment/moment";
import { CurrencyEnum } from "@core/store/types/common/enums/currencyEnum";
import {
  AdvertisersManagementCompanyService
} from "@core/services/admin/users/advertisers/AdvertisersManagementCompanyService";
import { useStateUpdater } from "@/helpers/useStateUpdater";
import { useAdvertisers } from "@/stores/admin/users/advertisers/advertisersStore";
import { CREATE_COST, REMOVE_COST, SWITCH_WRITE_OF } from "@/core/src/store/action-constants";

export const useAdvertisersManagementCompany = defineStore("admin/users/advertisers/managementCompany", {
  state: (): ManagementCompanyStore => ({
    isActive: false,
    isManagementCompanyWriteOffActive: false,
    managementCompanyCosts: [],
    advertiserId: "",
    input: {
      applyDate: momentToDate(momentjs().add(1, "month")),
      amount: {
        value: null,
        currency: CurrencyEnum.USD
      }
    }
  }),

  actions: {
    async [SWITCH_WRITE_OF] () {
      const item = await AdvertisersManagementCompanyService.switchingManagementCompany(this.advertiserId);
      useStateUpdater(useAdvertisers).LOCAL_UPDATE({ items: [item] });
    },

    async [CREATE_COST] () {
      const item = await AdvertisersManagementCompanyService.creatingManagementCompanyCost(this.advertiserId, this.input);
      this.managementCompanyCosts = item.managementCompanyCosts;
      useStateUpdater(useAdvertisers).LOCAL_UPDATE({ items: [item] });
    },

    async [REMOVE_COST] (id: string) {
      const item = await AdvertisersManagementCompanyService.removeManagementCompanyCost(id);
      this.managementCompanyCosts = item.managementCompanyCosts;
      useStateUpdater(useAdvertisers).LOCAL_UPDATE({ items: [item] });
    }
  }
});