<template>
    <div class="columns is-multiline">
        <Permissions :permissions="['WEBMASTERS.LIST.ALL', 'WEBMASTERS.LIST.OWN', 'WEBMASTERS.LIST.UNBIND']">
            <div class="column is-2">
                <b-field label=" ">
                    <WebmastersSelect
                        v-model="filters.webmasterId"
                        route-param="webmasterId"
                        namespace-module="admin/users/advertisers/detail/webmastersFilters"
                        :placeholder="$t(`admin.users.advertisers.modal.details.header.filters.labels.webmaster`)">
                    </WebmastersSelect>
                </b-field>
            </div>
        </Permissions>
        <div class="column is-2">
            <b-field label=" ">
                <OffersSelect
                    v-model="filters.offerId"
                    :vertical="advertiser.systemVertical"
                    :placeholder="$t('admin.users.advertisers.modal.details.header.filters.labels.offer')">
                </OffersSelect>
            </b-field>
        </div>
        <div class="column is-2">
            <b-field label=" ">
                <Select
                    v-model="filters.groups[0]"
                    :get-data="getGroups"
                    :searchable="false"
                    placeholder="-"
                    field="label"
                    prop="value"
                    expanded>
                </Select>
            </b-field>
        </div>
        <div class="column is-narrow">
            <b-field>
                <CurrencyCheckbox
                    v-model="filters.currency"
                    single>
                </CurrencyCheckbox>
            </b-field>
        </div>
        <div class="column is-narrow">
            <CustomDatepicker
                v-model="filters.datepicker"
                class="is-pulled-right"
                label=" "></CustomDatepicker>
        </div>
    </div>
</template>

<script>
  import Select from "@/components/Common/Select/Select.vue";
  import CurrencyCheckbox from "@/components/Common/Controls/CurrencyCheckbox.vue";
  import OffersSelect from "@/components/Common/Select/OffersSelect.vue";
  import CustomDatepicker from "@/components/Common/Date/CustomDatepicker.vue";
  import Permissions from "@/components/Common/Permissions.vue";
  import { Filters, UsersFilters } from "@core/mixins";
  import WebmastersSelect from "@/components/Common/Select/WebmastersSelect.vue";

  export default {
    name: "DetailsFilters",
    mixins: [Filters, UsersFilters],
    components: {
      Select,
      Permissions,
      CustomDatepicker,
      CurrencyCheckbox,
      OffersSelect,
      WebmastersSelect
    },
    props: {
      advertiser: {
        type: Object,
        default: null
      }
    },
    methods: {
      getGroups () {
        const items = ["day", "month", "webmaster"].map(group => ({
          label: this.$t(`common.groupsList.${ group }`),
          value: group
        }));
        return { items, count: items.length };
      },

      clearFilters () {
        this.emitClear();
      }
    }
  };
</script>

<style lang="scss" scoped>
    .column {
        flex-basis: 240px;
    }
</style>
