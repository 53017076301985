



















import SectionWrapper from "@/components/Common/SectionWrapper.vue";
import AdvertisersEditModal from "@/components/Admin/Users/AdvertisersTab/AdvertisersEditModal.vue";
import AdvertisersFilters from "@/components/Admin/Users/AdvertisersTab/AdvertisersFilters.vue";
import AdvertisersTable from "@/components/Admin/Users/AdvertisersTab/AdvertisersTable.vue";
import { storeToRefs } from "pinia";
import AdvertisersManagementCompanyModal from "@/components/Admin/Users/AdvertisersTab/AdvertisersManagementCompanyModal.vue";
import { useAdvertisersManagementCompany } from "@/stores/admin/users/advertisers/advertisersManagementCompanyStore";
import AdvertisersTableDetailsModal from "@/components/Admin/Users/AdvertisersTab/AdvertisersTableDetailsModal.vue";
import { useAdvertisersDetail } from "@/stores/admin/users/advertisers/advertisersDetailStore";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const managementCompanyStore = useAdvertisersManagementCompany();
  const {
    isActive: isActiveManagement
  } = storeToRefs(managementCompanyStore);
  const detailStore = useAdvertisersDetail();
  const {
    isActive: isActiveDetail
  } = storeToRefs(detailStore);
  return {
    isActiveManagement,
    isActiveDetail
  };
};
__sfc_main.components = Object.assign({
  SectionWrapper,
  AdvertisersFilters,
  AdvertisersTable,
  AdvertisersEditModal,
  AdvertisersManagementCompanyModal,
  AdvertisersTableDetailsModal
}, __sfc_main.components);
export default __sfc_main;
