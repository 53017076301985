import { defineStore, storeToRefs } from "pinia";
import { UsersEnum } from "@core/store/modules/admin/users/enums/UsersEnum";
import { computed, ref, watch } from "@vue/composition-api";
import { AdministratorsEditService } from "@core/services/admin/users/administrators/AdministratorsEditService";
import { WebmastersEditService } from "@core/services/admin/users/webmasters/WebmastersEditService";
import { AdvertisersEditService } from "@core/services/admin/users/advertisers/AdvertisersEditService";
import storeInstance from "@/store";
import { useAdvertisersEdit } from "@/stores/admin/users/advertisers/advertisersEditStore";

export function usePassword (user: UsersEnum) {
  return defineStore("admin/password", () => {
    const isActive = ref(false);
    const password = ref<string>("");

    const service = computed(() => {
      switch (user) {
        case UsersEnum.MANAGER: return AdministratorsEditService;
        case UsersEnum.WEBMASTER: return WebmastersEditService;
        case UsersEnum.ADVERTISER: return AdvertisersEditService;
        default: return AdministratorsEditService;
      }
    });

    const userId = computed(() => {
      const editStore = useAdvertisersEdit();
      const { advertiserId } = storeToRefs(editStore);
      switch (user) {
        case UsersEnum.MANAGER: return storeInstance.state.admin.users.administrators.edit.administratorId
               ?? storeInstance.state.admin.profile.user.id;
        case UsersEnum.WEBMASTER: return storeInstance.state.webmasterDataById.webmasterProfileViewModal.data?.id;
        case UsersEnum.ADVERTISER:return advertiserId.value;
        default: return storeInstance.state.admin.profile.user.id;
      }
    });

    async function EDIT_PASSWORD () {
      await service.value.editPassword(userId.value, { password: password.value });
    }

    function $reset () {
      isActive.value = false;
      password.value = "";
    }

    watch(() => isActive.value, value => !value && $reset());

    return {
      isActive,
      password,
      EDIT_PASSWORD,
      $reset
    };
  })();
}