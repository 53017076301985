<template>
    <div class="columns is-paddingless">
        <div class="column py-0">
            <div class="columns times is-variable is-1">
                <div class="column">
                    <b-field>
                        <b-timepicker
                            v-model="_time.timeStart"
                            :default-minutes="0"
                            :disabled="disabled"
                            :max-time="getMinMaxTime(time.timeFinish)"
                            :placeholder="$t(`common.entity.callCenters.from`)"
                            :required="!!time.timeFinish"
                            :time-creator="timeCreator"
                            editable
                            size="is-small">
                        </b-timepicker>
                    </b-field>
                </div>
                <div class="column">
                    <b-field>
                        <b-timepicker
                            v-model="_time.timeFinish"
                            :default-minutes="0"
                            :disabled="disabled"
                            :min-time="getMinMaxTime(time.timeStart)"
                            :placeholder="$t(`common.entity.callCenters.to`)"
                            :required="!!time.timeStart"
                            :time-creator="timeCreator"
                            :time-formatter="timeFormatter"
                            editable
                            size="is-small">
                            <b-checkbox
                                :value="isMidnight(time.timeFinish)"
                                @input="setToEndDay">
                                <span class="nowrap">
                                    {{ $t(`common.entity.callCenters.toDayEnd`) }}
                                </span>
                            </b-checkbox>
                        </b-timepicker>
                    </b-field>
                </div>
                <div class="column is-narrow">
                    <b-button
                        :disabled="disabled"
                        size="is-small"
                        type="is-danger is-light"
                        @click="deleteTime">
                        <b-icon
                            icon="times"
                            size="is-small"></b-icon>
                    </b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import { CallCenters } from "@core/mixins";
  
  export default {
    name: "DayTimeRow",
    mixins: [CallCenters],
    props: {
      time: {
        type: Object,
        default: null
      },
      isMasterDay: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      index: {
        type: Number,
        default: null
      }
    },
  
    computed: {
      _time: {
        get () {
          return this.time;
        },
      
        set (value) {
          this.$emit("update:time", value);
        }
      }
    },
  
    methods: {
      setToEndDay () {
        const value = this.time.timeFinish && this.isMidnight(this.time.timeFinish)
          ? null
          : new Date(this.currentYear, this.currentMonth, 2);
      
        this.$set(this.time, "timeFinish", value);
      },
    
      getMinMaxTime (time) {
        if (time?.getDate() === 2 || !this.isMasterDay) {
          return null;
        } return time;
      },

      deleteTime () {
        this.$emit("deleteTime", this.index);
      },

      timeFormatter (time) {
        return new Intl.DateTimeFormat("en-US", {
          hour: "numeric", minute: "numeric", hourCycle: time.getUTCMinutes() === 0 ? "h24" : "h23"
        }).format(time);
      },

      timeCreator () {
        return new Date(this.currentYear, this.currentMonth, 1);
      }
    },

    watch: {
      "time.timeFinish" (value) {
        if (value && this.isMidnight(value)) {
          this._time.timeFinish.setDate(2);
        }
      }
    }

  };
</script>

<style scoped>
    .columns.is-variable .column {
        padding-top: 0.60rem;
        padding-bottom: 0;
    }
</style>
