var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TablePlaceholder',{attrs:{"data":_vm.data},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var count = ref.count;
return [_c('LTable',{ref:"table",attrs:{"data":items,"loading":_vm.isLoadingTable,"query-pagination":"","count":count,"virtual-scroll":"","backend-pagination":"","backend-sorting":"","with-options":"","default-sort":_vm.defaultSort},on:{"update":function($event){return _vm.store.GET_ADVERTISERS()},"sort":_vm.updateSortings}},[_c('TableColumn',{attrs:{"label":_vm.$t("admin.users.advertisers.table.labels.id"),"field":"intId","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatEmptyString(row.intId))+" ")]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t("admin.users.advertisers.table.labels.login")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"is-flex is-align-items-center nowrap"},[_c('ColoredStatus',{staticClass:"mr-1",attrs:{"tooltip":_vm.$t(("admin.users.advertisers.filters.values." + (row.isApproved ? 'confirmed' : 'unconfirmed'))),"position":"both","tooltip-position":"is-bottom","tooltip-type":"is-dark","type":row.isApproved ? 'is-success' : 'is-danger'},scopedSlots:_vm._u([{key:"after",fn:function(){return [(row.activity.isBlocked)?_c('LTooltip',{attrs:{"label":_vm.$t("admin.users.advertisers.filters.values.blocked"),"position":"is-bottom","type":"is-dark"}},[_c('b-icon',{staticClass:"has-text-light",attrs:{"icon":"ban","size":"is-small"}})],1):_vm._e(),(!row.activity.isConfirmed)?_c('LTooltip',{attrs:{"label":_vm.$t("admin.users.advertisers.filters.values.emailUnConfirmed"),"position":"is-bottom","type":"is-dark"}},[_c('b-icon',{staticClass:"has-text-light line-through",attrs:{"icon":"envelope","size":"is-small"}})],1):_vm._e()]},proxy:true}],null,true)},[_c('AdvertiserOutput',{attrs:{"advertiser":{ intId: row.intId, login: row.login, id: row.id, avatar: row.avatar },"avatar":"","login":""}})],1),(row.isApproved && _vm.isShowCommissions)?_c('LTooltip',{staticClass:"ml-auto",attrs:{"label":_vm.$t("admin.users.advertisers.table.values.detailsModalLabel"),"position":"is-bottom","type":"is-info"}},[_c('b-tag',{staticClass:"is-clickable",attrs:{"type":"is-info is-light","disabled":""},nativeOn:{"click":function($event){return _vm.advertisersTableDetailsModalOpen(row)}}},[_c('b-icon',{attrs:{"icon":"signal","size":"is-small"}})],1)],1):_vm._e()],1)]}}],null,true)}),_c('b-table-column',{attrs:{"field":"preferredVertical","label":_vm.$t('admin.users.advertisers.table.labels.preferredVertical')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var systemVertical = ref.row.systemVertical;
return [_vm._v(" "+_vm._s(_vm.translateVertical(systemVertical))+" ")]}}],null,true)}),_c('TableColumn',{staticClass:"nowrap",attrs:{"field":"balance","label":_vm.$t('admin.users.webmasters.table.labels.balance'),"sortable":""},scopedSlots:_vm._u([{key:"header-content",fn:function(ref){
var column = ref.column;
return [_c('div',{staticClass:"is-inline-flex"},[_c('PreferredCurrencyCheckbox',{attrs:{"is-visible-preferred-currency":_vm.isVisiblePreferredCurrency,"name-local-storage":"isVisiblePreferredCurrencyAdvertisersBalance"},on:{"update:isVisiblePreferredCurrency":function($event){_vm.isVisiblePreferredCurrency=$event},"update:is-visible-preferred-currency":function($event){_vm.isVisiblePreferredCurrency=$event}}}),_vm._v(" "+_vm._s(column.label)+" ")],1)]}},{key:"default",fn:function(ref){
var row = ref.row;
return [(!row.balance)?_c('InlineLoader',{attrs:{"active":""}}):_vm._l((row.balance),function(ref,idx){
var balance = ref.balance;
return _c('span',{key:idx},[(_vm.preferredCurrency === balance.currency
                                    && !_vm.isVisiblePreferredCurrency)?[_vm._v(" "+_vm._s(_vm.fixedCurrency(balance))+" ")]:_vm._e(),(_vm.isVisiblePreferredCurrency)?[_vm._v(" "+_vm._s(_vm.fixedCurrency(balance))+" "),_c('br')]:_vm._e()],2)})]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t("admin.users.advertisers.table.labels.country"),"header-class":"nowrap"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var row = ref.row;
return [_c('Countries',{attrs:{"items":row.country ? [row.country] : [],"compact":"","tooltip-position":"is-top"}})]}}],null,true)}),_c('TableColumn',{attrs:{"label":_vm.$t("admin.users.advertisers.table.labels.offersAmount"),"header-class":"nowrap","field":"offersAmount","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatEmptyString(row.offersAmount))+" ")]}}],null,true)}),_c('TableColumn',{attrs:{"label":_vm.$t("admin.users.advertisers.table.labels.minimalCommission"),"header-class":"nowrap","field":"minimalCommission","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var row = ref.row;
return _vm._l((_vm.currencyAvailableToSort(row.minimalCommission)),function(item,idx){return _c('span',{key:idx},[_vm._v(" "+_vm._s(_vm.fixedCurrency(item))+" "),_c('br')])})}}],null,true)}),_c('TableColumn',{attrs:{"label":_vm.$t("admin.users.advertisers.table.labels.totalPayments"),"header-class":"nowrap","field":"totalPayments","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var row = ref.row;
return _vm._l((_vm.currencyAvailableToSort(row.totalPayments)),function(item,idx){return _c('span',{key:idx},[_vm._v(" "+_vm._s(_vm.fixedCurrency(item))+" "),_c('br')])})}}],null,true)}),_c('TableColumn',{attrs:{"label":_vm.$t("admin.users.advertisers.table.labels.lastTransactionDate"),"header-class":"nowrap","field":"lastTransactionDate","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.momentWithEmpty(row.lastTransactionDate))+" ")]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t("admin.users.advertisers.table.labels.penalties")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var row = ref.row;
return [(row.isFineDebt)?_c('span',{staticClass:"penalties"},[_vm._v(" "+_vm._s(row.fineDebtPercent)+"%/"+_vm._s(row.fineDebtDays)+_vm._s(_vm.$t("webmaster.offer.aside.shortUnitsTime.days"))+" ")]):[_vm._v(" - ")]]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t("admin.users.advertisers.table.labels.email")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var email = ref.row.contact.email;
return [_c('ContactOutput',{attrs:{"value":email,"type":"email"}})]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t("common.entity.contacts")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var contact = ref.row.contact;
return [_c('UserContacts',{attrs:{"contacts":contact}})]}}],null,true)}),_c('TableColumn',{attrs:{"label":_vm.$t("admin.users.advertisers.table.labels.regDate"),"field":"registeredAt","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.momentWithEmpty(row.activity.registeredAt))+" ")]}}],null,true)}),_c('TableColumn',{attrs:{"label":_vm.$t("admin.users.webmasters.table.labels.lastVisit")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var row = ref.row;
return [_c('div',{staticClass:"flex is-flex-direction-column is-align-items-start"},[_c('span',[_vm._v(" "+_vm._s(_vm.momentWithEmpty( row.lastVisit))+" ")]),(row.lastVisit)?_c('span',{staticClass:"has-text-grey has-text-centered",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.momentFrom(row.lastVisit))+" ")]):_vm._e()])]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t("admin.users.advertisers.table.labels.admin")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var row = ref.row;
return [_c('AdminOutput',{attrs:{"admin":row.admin}})]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t("admin.users.webmasters.table.labels.comment")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var row = ref.row;
return [_c('div',{staticClass:"is-flex is-align-items-center"},[_c('ShowMore',{attrs:{"data":row.comment,"max-string":50}}),_c('DropdownComment',{ref:("dropdown-" + (row.id)),attrs:{"is-loading":_vm.isLoadingComment,"value":row.comment},on:{"update":function($event){return _vm.changeComment($event, row.id)}}})],1)]}}],null,true)}),_c('b-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var row = ref.row;
return [_c('AdvertisersTableOptions',{attrs:{"row":row}})]}}],null,true)})],1),_c('FinancesIncomesCreatePaymentModal',{on:{"update":function($event){return _vm.store.GET_ADVERTISERS_WITH_BALANCE()}}}),_c('CallCenters')]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }