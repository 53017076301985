import { defineStore } from "pinia";
import { ApprovedListState } from "@core/store/types/admin/lists/ApprovedListState";
import { GET_APPROVED } from "@core/store/action-constants";
import i18n from "@core/plugins/i18n";

export const useApprovedList = defineStore("admin/lists/approvedList", {
  state: (): ApprovedListState => ({
    data: {}
  }),
    
  actions: {
    [GET_APPROVED] () {
      const items = [
        { label: i18n.t("admin.users.webmasters.filters.values.confirmed"), value: true },
        { label: i18n.t("admin.users.webmasters.filters.values.unconfirmed"), value: false }
      ];
      this.data = { items, count: items.length };
    }
  }
});