

















































































































































































































































































import AdminOutput from "@/components/Common/Output/AdminOutput.vue";
import DropdownComment from "@/components/Common/Controls/DropdownComment.vue";
import AdvertiserOutput from "@/components/Common/Output/AdvertiserOutput.vue";
import InlineLoader from "@/components/Common/Controls/InlineLoader.vue";
import ShowMore from "@/components/Common/ShowMore";
import TablePlaceholder from "@/components/Common/Table/TablePlaceholder";
import LTooltip from "@/components/Common/Tooltip/LTooltip.vue";
import { EDIT_ADVERTISER_ADMIN_COMMENT, GET_ADVERTISERS } from "@core/store/action-constants";
import ColoredStatus from "@/components/Common/ColoredStatus";
import FinancesIncomesCreatePaymentModal from "@/components/Admin/Finances/FinancesIncomes/FinancesIncomesCreatePaymentModal";
import PreferredCurrencyCheckbox from "@/components/Common/Controls/PreferredCurrencyCheckbox.vue";
import AdvertisersTableOptions from "@/components/Admin/Users/AdvertisersTab/AdvertisersTableOptions";
import CallCenters from "@/components/Admin/Users/AdvertisersTab/CallСenters/CallCenters";
import Countries from "@/components/Common/Lists/Countries.vue";
import LTable from "@/components/Common/LTable";
import { currencyAvailableToSort, fixedCurrency, momentWithEmpty } from "@core/flowMethods";
import { actionWithToast } from "@/helpers/actionWithToast";
import _startCase from "lodash/startCase";
import { formatEmptyString, momentFrom } from "@core/filters";
import { ComponentPublicInstance, computed, getCurrentInstance, ref, watch } from "@vue/composition-api";
import { useAdvertisers } from "@/stores/admin/users/advertisers/advertisersStore";
import { storeToRefs } from "pinia";
import { useWait } from "@/stores/common/waitStore";
import storeInstance from "@/store";
import { i18n } from "@core/plugins";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";
import { useMetrika } from "@core/plugins/vue/MetrikaPlugin";
import { AdvertisersItem } from "@core/store/types/admin/users/advertisers/AdvertisersState";
import { AdvertisersSortEnum } from "@core/store/types/admin/users/advertisers/enums/AdvertisersSortEnum";
import { OrderEnum } from "@core/store/types/common/enums/OrderEnum";
import { useAdvertisersDetail } from "@/stores/admin/users/advertisers/advertisersDetailStore";
import ContactOutput from "@/components/Common/Output/ContactOutput.vue";
import UserContacts from "@/components/Common/Output/UserContacts.vue";
import { useOffers } from "@/stores/admin/offers/offersStore";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const table = ref<ComponentPublicInstance<any> | null>(null);
  const isVisiblePreferredCurrency = ref(true);
  const detailsModal = ref(false);
  const detailsModalData = ref<AdvertisersItem | null>(null);
  const defaultSort = ref<[AdvertisersSortEnum, OrderEnum] | never[]>([]);
  const app = getCurrentInstance();
  const store = useAdvertisers();
  const {
    data
  } = storeToRefs(store);
  const detailStore = useAdvertisersDetail();
  const {
    isShowCommissions
  } = useOffers();
  const isLoadingComment = useWait(store, EDIT_ADVERTISER_ADMIN_COMMENT);
  const isLoadingTable = useWait(store, GET_ADVERTISERS);
  const preferredCurrency = computed(() => storeInstance.state.auth.preferredCurrency);
  function translateVertical(vertical: VerticalsEnum) {
    return vertical ? i18n.t(`dictionaries.verticals.${_startCase(vertical.toLowerCase())}`) : formatEmptyString(vertical);
  }
  function advertisersTableDetailsModalOpen(advertiser: AdvertisersItem) {
    useMetrika()?.reachGoal("USER_DETAILS");
    detailStore.$patch({
      isActive: true,
      advertiser
    });
  }
  function updateSortings(sort: AdvertisersSortEnum, order: OrderEnum) {
    if (sort && order) {
      table.value.$refs.lTable.resetMultiSorting();
      defaultSort.value = [sort, order];
      store.$patch({
        sort,
        order
      });
      store.GET_ADVERTISERS_WITH_BALANCE();
    }
  }
  async function changeComment(comment: string, userId: string) {
    const editableComment = {
      comment,
      userId
    };
    store.$patch({
      editableComment
    });
    await actionWithToast(store.EDIT_ADVERTISER_ADMIN_COMMENT(), "admin.users.advertisers.messages.comment");
    // @ts-ignore TODO: Переписать на function ref в vue 3
    app?.refs[`dropdown-${userId}`].toggle();
  }
  watch(() => detailsModal.value, value => !value && (detailsModalData.value = null));
  return {
    currencyAvailableToSort,
    fixedCurrency,
    momentWithEmpty,
    formatEmptyString,
    momentFrom,
    table,
    isVisiblePreferredCurrency,
    defaultSort,
    store,
    data,
    isShowCommissions,
    isLoadingComment,
    isLoadingTable,
    preferredCurrency,
    translateVertical,
    advertisersTableDetailsModalOpen,
    updateSortings,
    changeComment
  };
};
__sfc_main.components = Object.assign({
  TablePlaceholder,
  LTable,
  ColoredStatus,
  AdvertiserOutput,
  LTooltip,
  PreferredCurrencyCheckbox,
  InlineLoader,
  Countries,
  ContactOutput,
  UserContacts,
  AdminOutput,
  ShowMore,
  DropdownComment,
  AdvertisersTableOptions,
  FinancesIncomesCreatePaymentModal,
  CallCenters
}, __sfc_main.components);
export default __sfc_main;
