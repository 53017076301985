<template>
    <div>
        <b-button
            class="mb-4"
            expanded
            type="is-success is-light"
            @click="addCallCenter">
            {{ $t(`common.entity.callCenters.buttons.addCallCenter`) }}
        </b-button>
        <div>
            <TilesTable
                :columns="2"
                :items="callCentersUTC.items || []">
                <template slot-scope="{ item }">
                    <CallCenter
                        :call-center="item"
                        class="callCenter"
                        @deleteCallCenter="deleteCallCenter($event)"
                        @edit="editCallCenter($event)">
                    </CallCenter>
                </template>
            </TilesTable>
            <div
                v-if="!callCentersUTC.count"
                class="hero has-text-centered">
                <div class="hero-body">
                    <div class="is-size-5 is-bold has-text-weight-medium has-text-grey">
                        {{ $t(`common.entity.callCenters.labels.callCenterListIsEmpty`) }}
                    </div>
                </div>
            </div>
        </div>

        <LLoading
            :active="isLoading"
            :is-full-page="false"></LLoading>
        <InfiniteLoading @infinite="infiniteHandler">
            <div slot="spinner"></div>
            <div slot="no-more"></div>
            <div slot="no-results"></div>
        </InfiniteLoading>
    </div>
</template>

<script>
  import TilesTable from "@/components/Common/TilesTable";
  import { CallCenters } from "@core/mixins";
  import { DELETE_CALLCENTERS } from "@core/store/action-constants";
  import InfiniteLoading from "vue-infinite-loading";
  import { mapActions } from "vuex";
  import CallCenter from "@/components/Admin/Users/AdvertisersTab/CallСenters/CallCenter";
  import LLoading from "@/components/Common/LLoading";

  export default {
    name: "CallCentersList",
    mixins: [CallCenters],
    components: {
      LLoading,
      CallCenter,
      InfiniteLoading,
      TilesTable
    },

    props: {
      perPage: {
        type: Number,
        default: null
      },
      isLoading: {
        type: Boolean,
        default: null
      },
      getCallCenters: {
        type: Function,
        default: null
      }
    },

    methods: {
      ...mapActions("admin", [
        DELETE_CALLCENTERS
      ]),

      async infiniteHandler ($state) {
        await this.getCallCenters();
        if (this.callCentersUTC?.items.length && this.callCentersUTC.count > this.perPage) {
          this.$emit("updatePerPage");
          $state.loaded();
        } else {
          $state.complete();
        }
      },
      editCallCenter (callCenter) {
        this.$emit("openForm", callCenter);
      },
      addCallCenter () {
        this.$emit("openForm");
      },
      deleteCallCenter (id) {
        this.$buefy.dialog.confirm({
          title: this.$t("common.entity.callCenters.modals.deleteCallCenter.title"),
          message: this.$t("common.entity.callCenters.modals.deleteCallCenter.message"),
          confirmText: this.$t("common.entity.callCenters.modals.deleteCallCenter.confirmButtonText"),
          cancelText: this.$t("common.entity.callCenters.buttons.cancelButtonText"),
          type: "is-danger is-light",
          onConfirm: async () => {
            await this.DELETE_CALLCENTERS(id);
            await this.getCallCenters();
            this.$buefy.toast.open({
              message: this.$t("common.entity.callCenters.modals.deleteCallCenter.toast.success"),
              type: "is-success"
            });
          }
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
    //noinspection SassScssUnresolvedMixin
    @include widescreen {
        .callCenter {
            min-width: 24rem;
        }
    }
</style>
