<template>
    <TablePlaceholder :data="data">
        <CustomizableTable
            ref="customizableTable"
            :css="styling.table"
            :data-manager="dataManager"
            :fields="fields"
            :sort-order="sortOrder"
            :is-loading="isLoading">
            <template #tableHeader>
                <StatisticTableHeader
                    v-slot="{ fieldName }"
                    :styling="headerStyling">
                    <div class="table-header">
                        <label>
                            {{ $t(`advertiser.statistics.table.labels.${ fieldName }`) }}
                        </label>
                    </div>
                </StatisticTableHeader>
                <VuetableRowHeader></VuetableRowHeader>
            </template>
            <template #tableFooter="{ fields: footerFields }">
                <StatisticTableFooter
                    v-if="data.extras.group.length"
                    :average="data.aggregates.average"
                    :fields="footerFields"
                    :total="data.aggregates.total">
                </StatisticTableFooter>
            </template>
        </CustomizableTable>
    </TablePlaceholder>
</template>

<script>
  import CustomizableTable from "@/components/Common/Table/CustomizableTable";
  import StatisticTableFooter from "@/components/Common/Table/StatisticTableFooter";
  import StatisticTableHeader from "@/components/Common/Table/StatisticTableHeader";
  import TablePlaceholder from "@/components/Common/Table/TablePlaceholder";
  import StatisticTableViewGroup from "@/components/Common/Table/View/StatisticTableViewGroup";
  import StatisticTableViewIconsItem from "@/components/Common/Table/View/StatisticTableViewIconsItem";
  import VuetableMixin from "@/components/Common/Table/VuetableMixin";
  import { formatCurrency } from "@core/filters";
  import { Table } from "@core/mixins";
  import { GET_STATISTIC } from "@core/store/action-constants";
  import _orderBy from "lodash/orderBy";
  import { mapActions } from "vuex";
  import { fixedFormatPercent } from "@core/flowMethods";
  import { useOffers } from "@/stores/admin/offers/offersStore";
  
  export default {
    name: "DetailsTable",
    mixins: [Table, VuetableMixin],
    components: {
      StatisticTableFooter,
      StatisticTableHeader,
      TablePlaceholder,
      CustomizableTable
    },
    props: {
      currency: {
        type: String,
        default: null
      }
    },
    data () {
      return {
        sortOrder: []
      };
    },
    setup () {
      const { isShowCommissions } = useOffers();
      return { isShowCommissions };
    },
    computed: {
      fields () {
        if (!this.data) {
          return [];
        }
        const common = {
          name: StatisticTableViewGroup,
          headerName: "groups",
          titleClass: "has-text-centered has-text-grey-light",
          dataClass: "has-text-centered"
        };
        const keys = this.data.extras.group || [];
      
        const suffixes = {
          flow: "name",
          bunch: "id"
        };

        const groups = keys.reduce((groups, key) => {
          if (key === "offer") {
            const offerGroups = [
              {
                ...common,
                dataClass: "has-text-left",
                label: `${ key }.name`,
                sortField: `${ key }.name`,
                title: this.$t("common.groupsList.offer")
              }
            ];
            groups.push(...offerGroups);
          } else if (key === "webmaster") {
            groups.push({
              ...common,
              dataClass: "has-text-left",
              label: `${ key }.login`,
              sortField: `${ key }.login`,
              title: this.$t(`common.groupsList.${ key }`)
            });
          } else {
            const label = suffixes[key] ? `${ key }.${ suffixes[key] }` : key;
            groups.push({
              ...common,
              label,
              sortField: label,
              title: this.$t(`common.groupsList.${ key }`)
            });
          }

          return groups;
        }, []);

        return [
          ...groups,

          {
            ...common,
            name: StatisticTableViewIconsItem,
            headerName: "leads",
            label: "countLeadsCreated",
            sortField: "countLeadsCreated",
            title: this.$t("advertiser.statistics.table.labels.leads"),
            icon: "leads"
          },
          {
            ...common,
            name: StatisticTableViewIconsItem,
            headerName: "leads",
            label: "countLeadsCancelled",
            sortField: "countLeadsCancelled",
            title: this.$t("advertiser.statistics.table.labels.leadCancelled"),
            icon: "leadCancelled"
          },
          {
            ...common,
            name: StatisticTableViewIconsItem,
            headerName: "leads",
            label: "countLeadsAccepted",
            sortField: "countLeadsAccepted",
            title: this.$t("advertiser.statistics.table.labels.leadConfirmed"),
            icon: "leadConfirmed"
          },
          {
            ...common,
            name: StatisticTableViewIconsItem,
            headerName: "leads",
            label: "countLeadsProcessing",
            sortField: "countLeadsProcessing",
            title: this.$t("advertiser.statistics.table.labels.leadProcessed"),
            icon: "leadProcessed"
          },
          {
            ...common,
            name: StatisticTableViewIconsItem,
            headerName: "leads",
            label: "countLeadsSpammed",
            sortField: "countLeadsSpammed",
            title: this.$t("advertiser.statistics.table.labels.leadTrash"),
            icon: "leadTrash"
          },

          {
            ...common,
            name: StatisticTableViewIconsItem,
            headerName: "approve",
            label: "conversionApprove",
            sortField: "conversionApprove",
            title: this.$t("advertiser.statistics.table.labels.approve"),
            icon: "approve",
            formatter: (value) => fixedFormatPercent(value)
          },
          {
            ...common,
            name: StatisticTableViewIconsItem,
            headerName: "approve",
            label: "conversionCleanApprove",
            sortField: "conversionCleanApprove",
            title: this.$t("advertiser.statistics.table.labels.approveClean"),
            icon: "approveClean",
            formatter: (value) => fixedFormatPercent(value)
          },

          this.isShowCommissions && {
            ...common,
            name: StatisticTableViewIconsItem,
            headerName: "income",
            label: "moneyWebmasterAccrued",
            sortField: "moneyWebmasterAccrued",
            title: this.$t("advertiser.statistics.table.labels.accrued"),
            icon: "accruedDomonetization",
            formatter: value => formatCurrency(value, this.currency)
          },
          this.isShowCommissions && {
            ...common,
            name: StatisticTableViewIconsItem,
            headerName: "income",
            label: "moneyWebmasterAwaiting",
            sortField: "moneyWebmasterAwaiting",
            title: this.$t("advertiser.statistics.table.labels.awaiting"),
            icon: "leadProcessed",
            formatter: value => formatCurrency(value, this.currency)
          }
        ].filter(Boolean);
      }
    },
    methods: {
      ...mapActions("admin/statistic", [
        GET_STATISTIC
      ]),

      dataManager (sortOrder) {
        return _orderBy(this.data.items, sortOrder.map(el => el.sortField), sortOrder.map(el => el.direction));
      },

      headerStyling (name) {
        const headerStyles = {
          groups: { color: "black", backgroundColor: "#F7F7F7" },
          leads: { color: "white", backgroundColor: "#7adde2" },
          approve: { color: "white", backgroundColor: "#91e3d7" },
          income: { color: "white", backgroundColor: "#7adde2" },
          landing: { color: "white", backgroundColor: "#91e3d7" }
        };
        return headerStyles[name];
      }
    }
  };
</script>

<style lang="scss" scoped>
    @mixin table-shadow($color, $size) {
        & {
            position: relative;
        }

        &.is-shadow {
            &:not(:last-of-type):before {
                content: "";
                top: -1px;
                z-index: 10;
                right: 0;
                width: $size;
                position: absolute;
                pointer-events: none;
                height: calc(100% + 1px);
                box-shadow: inset (-$size) 0 $size (-$size) $color;
            }
        }
    }

    ::v-deep {
        .sort-icon {
            line-height: 1.5;
        }

        .v-popover {
            & + span {
                .sort-icon {
                    line-height: 2.4;
                }
            }
        }

        table {
            thead, tbody {
                background-color: white;
            }

            thead {
                tr {
                    &:first-child {
                        th {
                            padding: 0;

                            > div {
                                display: flex;
                                padding: 20px;
                                align-items: center;
                                justify-content: space-between;

                                label {
                                    text-transform: uppercase;
                                }
                            }
                        }
                    }
                }
            }
        }

        .b-table {
            $color: rgba(0, 0, 0, 0.1);
            $size: 20px;

            thead {
                top: 0;

                tr {
                    th {
                        @include table-shadow($color, $size);
                    }
                }
            }

            tbody, tfoot {
                tr {
                    td {
                        @include table-shadow($color, $size);
                    }
                }
            }

            tfoot {
                tr {
                    td {
                        z-index: 20;
                    }
                }
            }
        }
    }

    .card {
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    }

    .icon-btn {
        border: none;
        outline: none;
        cursor: pointer;
        background-color: transparent;
    }
</style>
