import Service from "@core/services/common/Service";
import switchManagementCompanyWriteOffQuery from "./graphql/switchManagementCompanyWriteOff.graphql";
import createManagementCompanyCostQuery from "./graphql/createManagementCompanyCost.graphql";
import removeManagementCompanyCostQuery from "./graphql/removeManagementCompanyCost.graphql";
import { ManagementCompanyInput } from "@core/store/types/admin/users/advertisers/AdvertisersState";

export class AdvertisersManagementCompanyService extends Service {
  public static async switchingManagementCompany (advertiserId: string) {
    try {
      const { data: { switchManagementCompanyWriteOff } } = await this.api.post("", {
        query: switchManagementCompanyWriteOffQuery,
        variables: {
          advertiserId
        }
      });
      return switchManagementCompanyWriteOff;
    } catch (e) {
      throw e;
    }
  }

  public static async creatingManagementCompanyCost (advertiserId: string, { applyDate, amount }: ManagementCompanyInput) {
    try {
      const { data: { createManagementCompanyCost } } = await this.api.post("", {
        query: createManagementCompanyCostQuery,
        variables: {
          advertiserId,
          input: {
            applyDate,
            amount: [amount]
          }
        }
      });
      return createManagementCompanyCost;
    } catch (e) {
      throw e;
    }
  }

  public static async removeManagementCompanyCost (id: string) {
    try {
      const { data: { removeManagementCompanyCost } } = await this.api.post("", {
        query: removeManagementCompanyCostQuery,
        variables: {
          id
        }
      });
      return removeManagementCompanyCost;
    } catch (e) {
      throw e;
    }
  }
}