









































import VerticalsSelect from "@/components/Common/Select/VerticalsSelect.vue";
import { defineEmits } from "@vue/runtime-dom";
import { ref, nextTick } from "@vue/composition-api";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const vertical = ref<string | null>(null);
  function submit() {
    emit("submit", vertical.value);
    nextTick(() => {
      emit("close");
    });
  }
  return {
    emit,
    vertical,
    submit
  };
};
__sfc_main.components = Object.assign({
  VerticalsSelect
}, __sfc_main.components);
export default __sfc_main;
