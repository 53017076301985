export function useTableOptions () {
  function webmasterOwnValidators (isBindedToCurrentAdmin: boolean) {
    const func = () => isBindedToCurrentAdmin;

    return {
      "WEBMASTERS.LOGIN.OWN": func,
      "WEBMASTERS.APPROVE.OWN": func,
      "WEBMASTERS.BLOCK.OWN": func,
      "WEBMASTERS.UNBLOCK.OWN": func
    };
  }

  function bindButtonType (isBoundToCurrentAdmin: boolean) {
    return isBoundToCurrentAdmin ? "is-danger is-light" : "is-success is-light";
  }

  function bindButtonIcon (isBoundToCurrentAdmin: boolean) {
    return isBoundToCurrentAdmin ? "user-minus" : "user-plus";
  }

  function blockButtonType (blocked: boolean) {
    return blocked ? "is-warning is-light" : "is-danger is-light";
  }

  function blockButtonIcon (blocked: boolean) {
    return blocked ? "unlock" : "lock";
  }

  function agentAccessButtonType (isAgent: boolean) {
    return isAgent ? "is-warning is-light" : "is-success is-light";
  }

  function apiButtonType (isEnableApi: boolean) {
    return isEnableApi ? "is-danger is-light" : "is-success is-light";
  }
    
  return {
    webmasterOwnValidators,
    bindButtonType,
    bindButtonIcon,
    blockButtonType,
    blockButtonIcon,
    agentAccessButtonType,
    apiButtonType
  };
}