
















import ModalBox from "@/components/Common/Modals/ModalBox.vue";
import AdvertisersTableDetailsModalDetails from "./AdvertisersTableDetailsModal/AdvertisersTableDetailsModalDetails";
import AdvertisersTableDetailsModalRates from "./AdvertisersTableDetailsModal/AdvertisersTableDetailsModalRates";
import { useAdvertisersDetail } from "@/stores/admin/users/advertisers/advertisersDetailStore";
import { storeToRefs } from "pinia";
import { onUnmounted } from "@vue/composition-api";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const detailStore = useAdvertisersDetail();
  const {
    isActive,
    advertiser
  } = storeToRefs(detailStore);
  onUnmounted(() => {
    detailStore.$reset();
  });
  return {
    isActive,
    advertiser
  };
};
__sfc_main.components = Object.assign({
  ModalBox,
  AdvertisersTableDetailsModalDetails,
  AdvertisersTableDetailsModalRates
}, __sfc_main.components);
export default __sfc_main;
