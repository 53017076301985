























































import PasswordModal from "@/components/Admin/Users/common/PasswordModal";
import AdvertiserProfile from "@/components/Common/Advertiser/AdvertiserProfile";
import ModalBox from "@/components/Common/Modals/ModalBox.vue";
import { EDIT_ADVERTISER, EDIT_PASSWORD } from "@core/store/action-constants";
import LLoading from "@/components/Common/LLoading";
import { actionWithToast } from "@/helpers/actionWithToast";
import { useAdvertisersEdit } from "@/stores/admin/users/advertisers/advertisersEditStore";
import { storeToRefs } from "pinia";
import { usePassword } from "@/stores/admin/common/passwordStore";
import { UsersEnum } from "@core/store/modules/admin/users/enums/UsersEnum";
import { useWait } from "@/stores/common/waitStore";
import { onUnmounted, watch } from "@vue/composition-api";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const editStore = useAdvertisersEdit();
  const {
    isActive,
    form
  } = storeToRefs(editStore);
  const passwordStore = usePassword(UsersEnum.ADVERTISER);
  const {
    isActive: isActivePassword,
    password
  } = storeToRefs(passwordStore);
  const isLoadingAdvertiser = useWait(editStore, EDIT_ADVERTISER);
  const isLoadingPassword = useWait(passwordStore, EDIT_PASSWORD);
  async function changePassword() {
    await actionWithToast(passwordStore.EDIT_PASSWORD(), "admin.users.common.modal.password.messages");
    isActivePassword.value = false;
  }
  async function editUser() {
    await actionWithToast(editStore.EDIT_ADVERTISER(), "admin.users.advertisers.modal.toast", undefined, {
      email: form.value.email
    });
    editStore.$reset();
  }
  watch(() => isActivePassword.value, value => {
    isActive.value = !value;
  });
  onUnmounted(() => {
    editStore.$reset();
  });
  return {
    isActive,
    form,
    isActivePassword,
    password,
    isLoadingAdvertiser,
    isLoadingPassword,
    changePassword,
    editUser
  };
};
__sfc_main.components = Object.assign({
  ModalBox,
  AdvertiserProfile,
  LLoading,
  PasswordModal
}, __sfc_main.components);
export default __sfc_main;
