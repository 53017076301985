


















































import TablePlaceholder from "@/components/Common/Table/TablePlaceholder";
import { GET_ADVERTISER_RATES } from "@core/store/action-constants";
import { useWait } from "@/stores/common/waitStore";
import { useAdvertisersDetail } from "@/stores/admin/users/advertisers/advertisersDetailStore";
import { ref, watchEffect } from "@vue/composition-api";
import { storeToRefs } from "pinia";
import LTable from "@/components/Common/LTable.vue";
import WebmasterOutput from "@/components/Common/Output/WebmasterOutput.vue";
import { fixedCurrency } from "@core/flowMethods";
import { formatEmptyString } from "@core/filters";
import CustomOfferOutput from "@/components/Common/Output/CustomOfferOutput.vue";
import { useOffers } from "@/stores/admin/offers/offersStore";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const detailStore = useAdvertisersDetail();
  const {
    data
  } = storeToRefs(detailStore);
  const {
    isShowCommissions
  } = useOffers();
  const isLoading = useWait(detailStore, GET_ADVERTISER_RATES);
  const defaultSort = ref<[string, string] | never[]>([]);
  const table = ref(null);
  const amounts = ["totalWriteOff", "individualWriteOff", isShowCommissions && "reward", isShowCommissions && "commission"].filter(Boolean);
  watchEffect(detailStore.GET_ADVERTISER_RATES);
  function updateSorting(sort: string, order: string) {
    defaultSort.value = [sort, order];
  }
  return {
    fixedCurrency,
    formatEmptyString,
    data,
    isLoading,
    defaultSort,
    table,
    amounts,
    updateSorting
  };
};
__sfc_main.components = Object.assign({
  TablePlaceholder,
  LTable,
  WebmasterOutput,
  CustomOfferOutput
}, __sfc_main.components);
export default __sfc_main;
