
























































































































































import DropdownOptions from "@/components/Common/DropdownOptions/DropdownOptions.vue";
import ModalBindUserToAdmin from "@/components/Admin/Users/ModalBindUserToAdmin.vue";
import DropdownOption from "@/components/Common/DropdownOptions/DropdownOption.vue";
import AdvertisersApproveModalTemplate from "./AdvertisersApproveModalTemplate.vue";
import Permissions from "@/components/Common/Permissions.vue";
import { SET_ADVERTISER_ID, SET_ADVERTISER_ID_CALLCENTERS, UPDATE_CALLCENTERS_MODAL_ACTIVE } from "@core/store/mutation-constants";
import { hasPermissions } from "@core/mixins/permissions";
import { actionWithToast } from "@/helpers/actionWithToast";
import { DELETE_ADVERTISER_PERSONAL_MANAGER, ADD_ADVERTISER_PERSONAL_MANAGER, ADMIN_LOGIN_AS_ADVERTISER, ADD_APPROVED_ADVERTISER, UPDATE_MODAL_ACTIVE, FORCE_LOGOUT_BY_ADMIN } from "@core/store/action-constants";
import logoutIcon from "@/assets/logout.svg";
import { defineProps } from "@vue/runtime-dom";
import { Admin, AdvertisersItem } from "@core/store/types/admin/users/advertisers/AdvertisersState";
import { computed, getCurrentInstance, ref } from "@vue/composition-api";
import storeInstance from "@/store";
import { useWait } from "@/stores/common/waitStore";
import { useAdvertisers } from "@/stores/admin/users/advertisers/advertisersStore";
import { DialogProgrammatic, ModalProgrammatic } from "buefy";
import { i18n } from "@core/plugins";
import { useAdvertisersEdit } from "@/stores/admin/users/advertisers/advertisersEditStore";
import { Advertiser } from "@core/store/types/admin/users/advertisers/Edit";
import _Vue from "vue";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";
import { useTableOptions } from "@/components/Admin/Users/useTableOptions";
import { useAdvertisersManagementCompany } from "@/stores/admin/users/advertisers/advertisersManagementCompanyStore";
interface Props {
  row: AdvertisersItem & Advertiser;
}
const __sfc_main = {};
__sfc_main.props = {
  row: {
    key: "row",
    required: true,
    type: Object
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const store = useAdvertisers();
  const editStore = useAdvertisersEdit();
  const managementCompanyStore = useAdvertisersManagementCompany();
  const app = getCurrentInstance();
  const {
    blockButtonIcon,
    blockButtonType
  } = useTableOptions();
  const advertiserUserLogin = ref<string | null>(null);
  const isBindModalVisible = ref(false);
  const advertiserUserId = ref<string | null>(null);
  const admin = ref<Admin[]>([]);
  const userPermissions = computed(() => storeInstance.state.auth.userPermissions);
  const isBindManager = computed(() => {
    const rules = ["ADVERTISERS.BIND.ALL"];
    if (props.row.isBindedToCurrentAdmin) {
      rules.push("ADVERTISERS.BIND.FREE");
    }
    return hasPermissions(rules, userPermissions.value);
  });
  const asAdmin = computed(() => storeInstance.getters.asAdmin);
  const isForceLogout = computed(() => storeInstance.getters["userLogout/isForceLogout"]);
  const adminIds = computed(() => [storeInstance.state.admin.profile.user.id]);
  const isLoadingBind = useWait(store, ADD_ADVERTISER_PERSONAL_MANAGER);
  const isLoadingUnBind = useWait(store, DELETE_ADVERTISER_PERSONAL_MANAGER);
  const isLoadingApprove = useWait(store, ADD_APPROVED_ADVERTISER);
  const isLoadingLoginAsAdvertiser = ref(false); // TODO: Переписать в будущем на useWait (ADMIN_LOGIN_AS_ADVERTISER)

  async function singInAsAdvertiser(id: string) {
    try {
      advertiserUserId.value = id;
      isLoadingLoginAsAdvertiser.value = true;
      await storeInstance.dispatch(ADMIN_LOGIN_AS_ADVERTISER, id);
      isLoadingLoginAsAdvertiser.value = false;
    } finally {
      advertiserUserId.value = null;
    }
  }
  function forceLogoutUser({
    id,
    login: user
  }: AdvertisersItem) {
    DialogProgrammatic.confirm({
      title: i18n.t("admin.users.common.confirm.logout.title") as string,
      message: i18n.t("admin.users.common.confirm.logout.subtitle", {
        user
      }) as string,
      confirmText: i18n.t("admin.users.advertisers.confirm.confirmButtonText") as string,
      cancelText: i18n.t("admin.users.advertisers.confirm.cancelButtonText") as string,
      type: "is-info is-light",
      size: "is-small",
      onConfirm: () => actionWithToast(storeInstance.dispatch(`userLogout/${FORCE_LOGOUT_BY_ADMIN}`, id), i18n.t("admin.users.common.messages.logout", {
        user
      }) as string)
    });
  }
  function onEditAdvertiser(row: Advertiser) {
    editStore.$patch({
      isActive: true
    });
    editStore.SET_ADVERTISERS_EDIT_FORM(row);
  }
  function onShowCallCenters(id: string) {
    storeInstance.commit(`admin/${UPDATE_CALLCENTERS_MODAL_ACTIVE}`, true);
    storeInstance.commit(`admin/${SET_ADVERTISER_ID_CALLCENTERS}`, id);
  }
  function onToggleBind({
    id,
    login,
    admin: user
  }: AdvertisersItem) {
    advertiserUserId.value = id;
    advertiserUserLogin.value = login;
    admin.value = user === null ? [] : [user];
    isBindModalVisible.value = true;
  }
  function advertiserOwnValidators(advertiser: AdvertisersItem) {
    const func = () => advertiser.isBindedToCurrentAdmin;
    return {
      "ADVERTISERS.LOGIN.OWN": func,
      "ADVERTISERS.APPROVE.OWN": func,
      "ADVERTISERS.BLOCK.OWN": func,
      "ADVERTISERS.UNBLOCK.OWN": func
    };
  }
  async function resendEmailByAdmin(id: string) {
    await actionWithToast(store.RESEND_EMAIL_BY_ADMIN(id), "common.mailConfirmation.resendEmail");
  }
  function bindToSelf({
    id: userId,
    login
  }: AdvertisersItem) {
    DialogProgrammatic.confirm({
      title: i18n.t("admin.users.advertisers.confirm.bindToSelf.title") as string,
      message: i18n.t("admin.users.advertisers.confirm.bindToSelf.message") as string,
      confirmText: i18n.t("admin.users.advertisers.confirm.bindToSelf.confirm") as string,
      cancelText: i18n.t("common.entity.modal.confirmationToCancel.cancelText") as string,
      type: "is-success is-light",
      onConfirm: async () => {
        await actionWithToast(store.ADD_ADVERTISER_PERSONAL_MANAGER({
          userId,
          adminIds
        }), "admin.users.advertisers.messages.bind", undefined, {
          login
        });
      }
    });
  }
  function onToggleBlock({
    id,
    login: advertiserLogin,
    activity: {
      isBlocked
    }
  }: AdvertisersItem) {
    const path = "admin.users.advertisers";
    const name = isBlocked ? "unblocked" : "blocked";
    DialogProgrammatic.confirm({
      title: i18n.t(`${path}.confirm.${name}`) as string,
      message: "",
      confirmText: i18n.t(`${path}.confirm.confirmButtonText`) as string,
      cancelText: i18n.t(`${path}.confirm.cancelButtonText`) as string,
      type: "is-info is-light",
      onConfirm: async () => {
        await actionWithToast(store.TOGGLE_BLOCK_ADVERTISER(isBlocked, id), `${path}.messages.${name}`, undefined, {
          advertiserLogin
        });
      }
    });
  }
  function onApproved({
    id,
    login: advertiserLogin
  }: AdvertisersItem) {
    ModalProgrammatic.open({
      parent: app?.proxy as unknown as _Vue,
      component: AdvertisersApproveModalTemplate,
      hasModalCard: true,
      props: {
        isLoading: isLoadingApprove.value
      },
      events: {
        submit: async (vertical: VerticalsEnum) => {
          try {
            advertiserUserId.value = id;
            await actionWithToast(store.ADD_APPROVED_ADVERTISER(id, vertical), "admin.users.advertisers.messages.approve", undefined, {
              advertiserLogin
            });
          } finally {
            advertiserUserId.value = null;
          }
        }
      }
    });
  }
  function openIncomeModal(id: string) {
    const path = "admin/finances/incomes/createIncomeModal";
    storeInstance.dispatch(`${path}/${UPDATE_MODAL_ACTIVE}`, true);
    storeInstance.commit(`${path}/${SET_ADVERTISER_ID}`, id);
  }
  function onOpenManagementCompany({
    isManagementCompanyWriteOffActive,
    managementCompanyCosts,
    id: advertiserId
  }: AdvertisersItem) {
    managementCompanyStore.$patch({
      isActive: true,
      isManagementCompanyWriteOffActive,
      managementCompanyCosts,
      advertiserId
    });
  }
  return {
    logoutIcon,
    store,
    blockButtonIcon,
    blockButtonType,
    advertiserUserLogin,
    isBindModalVisible,
    advertiserUserId,
    admin,
    isBindManager,
    asAdmin,
    isForceLogout,
    isLoadingBind,
    isLoadingUnBind,
    isLoadingApprove,
    isLoadingLoginAsAdvertiser,
    singInAsAdvertiser,
    forceLogoutUser,
    onEditAdvertiser,
    onShowCallCenters,
    onToggleBind,
    advertiserOwnValidators,
    resendEmailByAdmin,
    bindToSelf,
    onToggleBlock,
    onApproved,
    openIncomeModal,
    onOpenManagementCompany
  };
};
__sfc_main.components = Object.assign({
  DropdownOptions,
  Permissions,
  DropdownOption,
  ModalBindUserToAdmin
}, __sfc_main.components);
export default __sfc_main;
