<template>
    <PaymentModal
        :is-active="isModalActive"
        :is-loading="isLoadingCreateIncome"
        :user-id="user"
        :with-requisites="false"
        @submit="createPayment"
        @update:user="getBalance"
        @update:isActive="UPDATE_MODAL_ACTIVE($event)">
        <template #user="{ modal }">
            <b-field
                :label="$t(`admin.finances.incomes.modal.body.advertiser.title`)"
                expanded>
                <AdvertisersSelect
                    v-model="modal.user.id"
                    :disabled="user != null"
                    namespace-module="admin/finances/incomes/createIncomeModal/advertisersFilters"
                    :placeholder="$t(`admin.finances.incomes.modal.body.advertiser.label`)">
                </AdvertisersSelect>
            </b-field>
            <b-field
                v-if="balance != null"
                :label="$t(`admin.finances.incomes.modal.body.advertiser.balance`)">
                <b-button
                    class="has-text-weight-bold is-paddingless has-background-white is-12 column"
                    type="is-white">
                    <h5
                        :key="idx"
                        v-for="(item, idx) in balance"
                        class="title is-5 has-text-right mb-0">
                        {{ fixedCurrency(item.balance) }}
                    </h5>
                </b-button>
            </b-field>
        </template>
    </PaymentModal>
</template>

<script>
  import AdvertisersSelect from "@/components/Common/Select/AdvertisersSelect.vue";
  import { formatCurrency } from "@core/filters";
  import { UsersFilters } from "@core/mixins";
  import { CREATE_INCOME, GET_REQUISITES, GET_USER_BALANCE, UPDATE_MODAL_ACTIVE } from "@core/store/action-constants";
  import { SET_ADVERTISER_BALANCE } from "@core/store/mutation-constants";
  import { mapActions, mapMutations, mapState } from "vuex";
  import PaymentModal from "@/components/Admin/Finances/PaymentModal";
  import { SET_USER_BALANCE, UPDATE_MODAL_OPTIONS } from "@core/store/mutation-constants";
  import { actionWithToast } from "@/helpers/actionWithToast";
  import { fixedCurrency } from "@core/flowMethods";
  
  export default {
    name: "FinancesIncomesCreatePaymentModal",
    mixins: [UsersFilters],
    components: {
      PaymentModal,
      AdvertisersSelect
    },

    computed: {
      ...mapState("admin/finances/incomes/createIncomeModal", {
        balance: state => state.balance,
        user: state => state.advertiserId,
        isModalActive: state => state.isModalActive
      }),
      
      isLoadingCreateIncome () {
        return this.$wait(`admin/finances/incomes/createIncomeModal/${ CREATE_INCOME }`);
      }
    },

    methods: {
      ...mapActions("admin/finances/incomes/createIncomeModal", [
        CREATE_INCOME,
        GET_USER_BALANCE,
        GET_REQUISITES,
        UPDATE_MODAL_ACTIVE,
        UPDATE_MODAL_OPTIONS
      ]),

      ...mapMutations("admin/finances/incomes/createIncomeModal", {
        SET_ADVERTISER_BALANCE,
        SET_USER_BALANCE
      }),

      async getBalance (value) {
        if (value?.id) {
          await this.GET_USER_BALANCE(value.id);
        } else {
          this.SET_USER_BALANCE(null);
        }
      },

      async createPayment (modal) {
        const { amount, comment, paymentSystem: { currency, id }, user: { id: userId } } = modal;
        this.UPDATE_MODAL_OPTIONS({ amount, comment, currency, paymentSystemId: id, userId });

        await actionWithToast(this.CREATE_INCOME(), "admin.finances.incomes.messages.create");

        //TODO убрать когда во всех местах будет локальное изменение данных
        this.$emit("update");

        this.UPDATE_MODAL_ACTIVE(false);
      },

      fixedCurrency,
      formatCurrency
    }
  };
</script>

<style scoped></style>
