<template>
    <div>
        <RatesFilters :advertiser="advertiser"></RatesFilters>
        <RatesTable class="mt-3"></RatesTable>
    </div>
</template>

<script>
  import RatesFilters from "@/components/Admin/Users/AdvertisersTab/AdvertisersTableDetailsModal/Rates/RatesFilters";
  import RatesTable from "@/components/Admin/Users/AdvertisersTab/AdvertisersTableDetailsModal/Rates/RatesTable";

  export default {
    name: "AdvertisersTableDetailsModalRates",

    components: {
      RatesFilters,
      RatesTable
    },

    props: {
      advertiser: {
        type: Object,
        default: null
      }
    }
  };
</script>

<style scoped>

</style>
