<template>
    <table class="timetable">
        <tr v-if="!isEdit">
            <td class="day">
                <FormTimetableAllDays
                    :master-day.sync="masterDay"
                    :selected-days-names.sync="selectedDays"
                    :working-all-day.sync="workingAllDay">
                </FormTimetableAllDays>
            </td>
            <td colspan="3">
                <b-notification
                    :closable="false"
                    type="is-info">
                    <span>
                        {{ $t("common.entity.callCenters.labels.masterDay.notification") }}
                    </span>
                </b-notification>
            </td>
        </tr>
        <tr>
            <td
                :key="dayName"
                v-for="(_, dayName) in timetableWithMasterMix"
                class="day">
                <FormTimetableDay
                    :day-name="dayName"
                    :day.sync="timetableWithMasterMix[dayName]"
                    :master-day="masterDay"
                    :selected-days="selectedDays"
                    :working-all-day.sync="workingAllDay"
                    @addGapNextDay="addGapNextDay($event)"
                    @removeDay="removeDayInMaster">
                </FormTimetableDay>
            </td>
        </tr>
    </table>
</template>

<script>
  import { CallCenters } from "@core/mixins";
  import FormTimetableAllDays from "@/components/Admin/Users/AdvertisersTab/CallСenters/CallCentersForm/FormTimetableAllDays";
  import FormTimetableDay from "@/components/Admin/Users/AdvertisersTab/CallСenters/CallCentersForm/FormTimetableDay";
  
  export default {
    name: "FormTimetable",
    mixins: [CallCenters],
    components: {
      FormTimetableAllDays,
      FormTimetableDay
    },
    props: {
      editableCallCenter: {
        type: Object,
        default: null
      }
    },
    created () {
      this.timetable = this.createTimetable;
    },
    data () {
      return {
        timetable: {},
        selectedDays: [],
        workingAllDay: [],
        masterDay: {
          workAllDay: false,
          times: [{
            timeStart: null,
            timeFinish: null
          }]
        }
      };
    },
    computed: {

      createTimetable () {
        const timetable = {};
        this.daysOfWeek.forEach(day => {
          if (this.isEdit && this.editableCallCenter?.timeTables[day]) {timetable[day] = this.formatEditableTimetable(day);}
          else {
            timetable[day] = {
              workAllDay: false,
              times: [{
                timeStart: null,
                timeFinish: null
              }]
            };
          }
        });
        return timetable;
      },

      timetableWithMasterMix: {
        get () {
          const timetable = {};

          for (const [key, value] of Object.entries(this.timetable)) {
            if (this.selectedDays.includes(key)) {
              timetable[key] = this.masterDay;
            } else {
              timetable[key] = value;
            }
          }
          return timetable;
        },

        set (value) {
          this.week = value;
        }
      },

      isEdit () {
        return !!this.editableCallCenter;
      }
    },
    methods: {
      isWorkAllDay (day) {
        const time = this.editableCallCenter?.timeTables[day][0];
        return this.isMidnight(time.timeStart) && this.isMidnight(time.timeFinish);
      },

      removeDayInMaster (day) {
        this.selectedDays = this.selectedDays.filter(masterDay => masterDay !== day);
      },

      formatEditableTimetable (day) {
        return {
          workAllDay: this.isWorkAllDay(day),
          times: (() => this.editableCallCenter?.timeTables[day].map(day => {
            return {
              timeStart: new Date(day.timeStart),
              timeFinish: new Date(day.timeFinish)
            };
          })
          )()
        };
      },

      addGapNextDay (event) {
        let index = this.daysOfWeek.indexOf(event.dayName) + 1;
        index = index > 6 ? 0 : index;
        const newTime = {
          timeStart: new Date(this.currentYear, this.currentMonth, 1),
          timeFinish: event.timeFinish
        };
        this.timetableWithMasterMix[this.daysOfWeek[index]].times.unshift(newTime);
      }
    },
    watch: {
      timetableWithMasterMix () {
        this.$emit("update:timetable", this.timetableWithMasterMix);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .day {
    ::v-deep {
      .field {
        .label {
            //noinspection SassScssUnresolvedVariable
            font-size: $size-6;
            //noinspection SassScssUnresolvedVariable
            font-weight: $weight-semibold;
        }
        .checkbox {
          .control-label {
            font-size: $size-normal;
          }
        }
      }
    }
  }

  .timetable {
    $padding: 0.75rem * 2;
    table-layout: fixed;
    border-spacing: $padding;
    border-collapse: separate;
    margin: (-$padding) (-$padding) 0;
  }
</style>
