








































































































































import ManagersSelect from "@/components/Common/Select/ManagersSelect.vue";
import LFilters from "@/components/Common/LFilters";
import { GET_ADVERTISERS } from "@core/store/action-constants";
import VerticalsSelect from "@/components/Common/Select/VerticalsSelect.vue";
import { useAdvertisers } from "@/stores/admin/users/advertisers/advertisersStore";
import { storeToRefs } from "pinia";
import { useWait } from "@/stores/common/waitStore";
import { useFiltersConfirm } from "@/stores/common/filtersConfirmStore";
import BlockedList from "@/components/Common/Lists/BlockedList.vue";
import ApprovedList from "@/components/Common/Lists/ApprovedList.vue";
import EmailConfirmedList from "@/components/Common/Lists/EmailConfirmedList.vue";
import { computed, onUnmounted } from "@vue/composition-api";
import { AdvertisersState } from "@core/store/types/admin/users/advertisers/AdvertisersState";
import { advertisersAdvancedFilters } from "@/stores/admin/users/advertisers/datasets/advertisersAdvancedFilters";
import vuexStore from "@/store";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const validateNumber = "return (event.charCode !== 8 && event.charCode === 0 || event.charCode >= 48 && event.charCode <= 57)";
  const advancedFilters = Object.keys(advertisersAdvancedFilters);
  const store = useAdvertisers();
  const {
    filters
  } = storeToRefs(store);
  const confirmStore = useFiltersConfirm();
  const {
    confirmFilters
  } = storeToRefs(confirmStore);
  const isLoading = useWait(store, GET_ADVERTISERS);
  const gamblingOnly = computed(() => vuexStore.getters["verticals/gamblingOnly"]);
  const allVerticals = computed(() => vuexStore.getters["verticals/allVerticals"]);
  const nutraOnly = computed(() => vuexStore.getters["verticals/nutraOnly"]);
  const visibleVerticals = allVerticals.value || gamblingOnly.value && nutraOnly.value;
  function onConfirmFilters({
    filters
  }: {
    filters: AdvertisersState["filters"];
  }) {
    store.$patch({
      filters
    });
    store.GET_ADVERTISERS();
  }
  onUnmounted(() => {
    store.$reset();
  });
  return {
    validateNumber,
    advancedFilters,
    store,
    filters,
    confirmFilters,
    isLoading,
    visibleVerticals,
    onConfirmFilters
  };
};
__sfc_main.components = Object.assign({
  LFilters,
  BlockedList,
  ApprovedList,
  ManagersSelect,
  VerticalsSelect,
  EmailConfirmedList
}, __sfc_main.components);
export default __sfc_main;
