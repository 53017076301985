<template>
    <CallCenterForm
        :action-on-save="(callCenter) => editCallCenter(callCenter)"
        :editable-call-center="editableCallCenter">
    </CallCenterForm>
</template>

<script>
  import { EDIT_TIMETABLE } from "@core/store/action-constants";
  import { mapActions } from "vuex";
  import CallCenterForm from "@/components/Admin/Users/AdvertisersTab/CallСenters/CallCentersForm/CallCenterForm";
  
  export default {
    name: "EditCallCenter",
    components: {
      CallCenterForm
    },
    props: {
      editableCallCenter: {
        type: Object,
        default: null
      }
    },
    methods: {
      ...mapActions("admin", [
        EDIT_TIMETABLE
      ]),
      async editCallCenter (callCenter) {
        await this.EDIT_TIMETABLE({
          callCenterId: this.editableCallCenter.id,
          callCenter
        });
        this.$buefy.toast.open({
          message: this.$t("common.entity.callCenters.messages.successEditing"),
          type: "is-success"
        });
        this.$emit("changeFile", "list");
      }
    }
  };
</script>

<style scoped>

</style>
