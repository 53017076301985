





































































































































import ModalBox from "@/components/Common/Modals/ModalBox.vue";
import { useAdvertisersManagementCompany } from "@/stores/admin/users/advertisers/advertisersManagementCompanyStore";
import { storeToRefs } from "pinia";
import { onUnmounted } from "@vue/composition-api";
import CustomCheckbox from "@/components/Common/Controls/CustomCheckbox.vue";
import Permissions from "@/components/Common/Permissions.vue";
import DateSelect from "@/components/Common/Date/DateSelect.vue";
import LTable from "@/components/Common/LTable.vue";
import { moment } from "@/filters";
import { fixedCurrency } from "@core/flowMethods";
import { useWait } from "@/stores/common/waitStore";
import { CREATE_COST, REMOVE_COST, SWITCH_WRITE_OF } from "@core/store/action-constants";
import FieldGroup from "@/components/Common/Fields/FieldGroup.vue";
import DropdownIsoCurrencies from "@/components/Common/DropdownOptions/DropdownIsoCurrencies.vue";
import { Money } from "@core/store/types/common";
import { CurrencyEnum } from "@core/store/types/common/enums/currencyEnum";
import LabelWithAnnotation from "@/components/Common/Tooltip/LabelWithAnnotation.vue";
import { momentToDate } from "@core/helpers/momentToDate";
import momentjs from "moment";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const managementCompanyStore = useAdvertisersManagementCompany();
  const {
    isActive,
    isManagementCompanyWriteOffActive,
    input,
    managementCompanyCosts
  } = storeToRefs(managementCompanyStore);
  const isLoadingSwitch = useWait(managementCompanyStore, SWITCH_WRITE_OF);
  const isLoadingCreate = useWait(managementCompanyStore, CREATE_COST);
  const isLoadingDelete = useWait(managementCompanyStore, REMOVE_COST);
  function isDeleteCost(data: Date) {
    return momentToDate(momentjs(data)) > momentToDate(momentjs().startOf("month").add(1, "month"));
  }
  function replaceToUSD(amount: Money[]) {
    return amount.find(({
      currency
    }) => currency! === CurrencyEnum.USD);
  }
  onUnmounted(() => {
    managementCompanyStore.$reset();
  });
  return {
    moment,
    fixedCurrency,
    managementCompanyStore,
    isActive,
    isManagementCompanyWriteOffActive,
    input,
    managementCompanyCosts,
    isLoadingSwitch,
    isLoadingCreate,
    isLoadingDelete,
    isDeleteCost,
    replaceToUSD
  };
};
__sfc_main.components = Object.assign({
  ModalBox,
  LabelWithAnnotation,
  Permissions,
  CustomCheckbox,
  FieldGroup,
  DropdownIsoCurrencies,
  DateSelect,
  LTable
}, __sfc_main.components);
export default __sfc_main;
