function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TablePlaceholder',{attrs:{"data":_vm.data},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var count = ref.count;
return [_c('LTable',{ref:"table",attrs:{"data":items,"count":count,"loading":_vm.isLoading,"default-sort":_vm.defaultSort,"virtual-scroll":"","hide-sticky":"","hide-pagination":"","backend-sorting":false},on:{"sort":_vm.updateSorting}},[_c('GroupColumns',{attrs:{"label-group":_vm.$t('admin.users.advertisers.modal.details.body.individualRates.labels.individualRate').toUpperCase()}},[_c('TableColumn',{attrs:{"sortable":"","field":"webmaster.login","label":_vm.$t('admin.users.advertisers.modal.details.body.individualRates.labels.webmaster')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var webmaster = ref.row.webmaster;
return [_c('WebmasterOutput',{attrs:{"webmaster":webmaster,"int-id":false}})]}}],null,true)}),_c('TableColumn',{attrs:{"sortable":"","field":"offer.detail.name","label":_vm.$t('admin.users.advertisers.modal.details.body.individualRates.labels.offer')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row_offer = ref.row.offer;
var name = ref_row_offer.detail.name;
var rest = objectWithoutProperties( ref_row_offer, ["detail"] );
var offer = rest;
return [_c('CustomOfferOutput',{attrs:{"offer":Object.assign({}, offer, {name: name})}})]}}],null,true)}),_vm._l((_vm.amounts),function(amount){return _c('TableColumn',{key:amount,attrs:{"sortable":"","field":(amount + ".value"),"cell-class":"has-text-left","label":_vm.$t(("admin.users.advertisers.modal.details.body.individualRates.labels." + amount))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row[amount] ? _vm.fixedCurrency(row[amount]) : _vm.formatEmptyString(null))+" ")]}}],null,true)})})],2)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }