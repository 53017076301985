<template>
    <FormTimetableDayWrapper :title="$t('common.entity.callCenters.labels.masterDay.title')">
        <template #params>
            <b-dropdown
                v-model="selectedDaysNames_"
                aria-role="list"
                expanded
                multiple>
                <button
                    slot="trigger"
                    class="button is-fullwidth"
                    type="button">
                    <div>
                        {{ selectedDaysNames.length ? "" : $t("common.entity.callCenters.labels.masterDay.selectDays")
                        }}
                        <span
                            :key="i"
                            v-for="(selectedDay, i) in selectedDaysNames">
                            {{ $t(`common.entity.callCenters.daysOfWeek.short.${ selectedDay }`) }}
                        </span>
                    </div>
                    <b-icon icon="caret-down"></b-icon>
                </button>
        
                <b-dropdown-item
                    :key="dayName"
                    v-for="dayName in daysOfWeek"
                    :value="dayName"
                    aria-role="listitem">
                    <span>{{ $t(`common.entity.callCenters.daysOfWeek.full.${ dayName }`) }}</span>
                </b-dropdown-item>
            </b-dropdown>
    
            <b-checkbox
                v-model="masterDay_.workAllDay"
                class="pt-2">
                <LTooltip
                    :label="$t('common.entity.callCenters.labels.allDayTooltip')"
                    position="is-bottom"
                    type="is-dark">
                    {{ $t("common.entity.callCenters.labels.allDay") }}
                </LTooltip>
            </b-checkbox>
        </template>
    
        <template #times>
            <DayTimeRow
                :key="index"
                v-for="(_, index) in masterDay.times"
                :disabled="masterDay_.workAllDay"
                :index="index"
                :is-master-day="true"
                :time.sync="masterDay_.times[index]"
                @deleteTime="deleteTime">
            </DayTimeRow>
        </template>
    
        <template #footer>
            <b-button
                :disabled="masterDay_.workAllDay"
                expanded
                icon-left="plus"
                size="is-small"
                type="is-light is-info"
                @click="addTime">
            </b-button>
        </template>
    </FormTimetableDayWrapper>
</template>

<script>
  import DayTimeRow from "@/components/Admin/Users/AdvertisersTab/CallСenters/CallCentersForm/DayTimeRow";
  import FormTimetableDayWrapper from "@/components/Admin/Users/AdvertisersTab/CallСenters/CallCentersForm/FormTimetableDayWrapper";
  
  export default {
    name: "FormTimetableAllDays",
    components: {
      DayTimeRow,
      FormTimetableDayWrapper
    },
    props: {
      masterDay: {
        type: Object,
        default: null
      },
      selectedDaysNames: {
        type: Array,
        default: null
      },
      workingAllDay: {
        type: Array,
        default: null
      }
    },
    data () {
      return {
        workAllDay: false
        // masterDay: [{
        //   timeStart: null,
        //   timeFinish: null
        // }]
      };
    },
    computed: {
      selectedDaysNames_: {
        get () {
          return this.selectedDaysNames;
        },
        set (value) {
          this.$emit("update:selectedDaysNames", value);
        }
      },

      // workingAllDay_: {
      //   get () {
      //     return this.workingAllDay;
      //   },
      //   set (value) {
      //     this.$emit("update:workingAllDay", value);
      //   }
      // },

      masterDay_:{
        get () {
          return this.masterDay;
        },
        set (value) {
          this.$emit("update:masterDay", value);
        }
      },
      daysOfWeek () {
        return ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
      },
      emptyTime () {
        return {
          timeStart: null,
          timeFinish: null
        };
      }
    },
    methods: {
      // updateTimetable(dayName) {
      //   if(this.selectedDaysNames.includes(dayName)) {
      //     this.$set(this.timetable_, dayName, this.masterDay);
      //   } else {
      //     this.$set(this.timetable_, dayName, [{
      //       timeStart: null,
      //       timeFinish: null
      //     }]);
      //   }
      // },
      addTime () {
        this.masterDay_.times.push({ ...this.emptyTime });
      },

      // setWorkingAllDay () {
      //   if(this.workAllDay) {
      //     this.workingAllDay_ = this.workingAllDay.concat(this.selectedDaysNames);
      //   } else {
      //     this.workingAllDay_ = this.workingAllDay.filter(workDay => !~this.selectedDaysNames.indexOf(workDay));
      //   }
      // },

      deleteTime (index) {
        if (index === 0) {
          this.$set(this.masterDay_.times, 0, { ...this.emptyTime });
        } else {
          this.masterDay_.times.splice(index, 1);
        }
      }
    }
    // watch: {
    //   workAllDay () {
    //     this.setWorkingAllDay();
    //   },
    //   selectedDaysNames () {
    //     this.setWorkingAllDay();
    //   }
    // }
  };
</script>

<style scoped>

</style>
