import { defineStore } from "pinia";
import { BlockedListState } from "@core/store/types/admin/lists/BlockedListState";
import { GET_BLOCKED } from "@core/store/action-constants";
import i18n from "@core/plugins/i18n";

export const useBlockedList = defineStore("admin/lists/blockedList", {
  state: (): BlockedListState => ({
    data: {}
  }),
    
  actions: {
    [GET_BLOCKED] () {
      const items = [
        { label: i18n.t("admin.users.webmasters.filters.values.blocked"), value: true },
        { label: i18n.t("admin.users.webmasters.filters.values.notBlocked"), value: false }
      ];
      this.data = { items, count: items.length };
    }
  }
});